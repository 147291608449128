import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, useTheme, useMediaQuery } from '@mui/material';

const oemodmImages = [
  {
    title: "Machinery 1",
    image: "/images/mach1.jpg",  // 替換為你的 mach1 圖片路徑
  },
  {
    title: "Machinery 2",
    image: "/images/mach2.jpg",  // 替換為你的 mach2 圖片路徑
  },
  {
    title: "Machinery 3",
    image: "/images/mach3.jpg",  // 替換為你的 mach3 圖片路徑
  },
  {
    title: "Machinery 4",
    image: "/images/mach4.jpg",  // 替換為你的 mach4 圖片路徑
  },
  {
    title: "Machinery 5",
    image: "/images/mach5.jpg",  // 替換為你的 mach5 圖片路徑
  },
  {
    title: "Machinery 6",
    image: "/images/mach6.png",  // 替換為你的 mach6 圖片路徑
  },
];

function OEMODM() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      id="oem-odm"
      sx={{
        bgcolor: 'background.default',
        color: 'text.primary',
        p: 2,
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{ mb: 4, fontWeight: 'bold', fontSize: isMobile ? '28px' : '36px',color: 'primary.main', }}
      >
        OEM & ODM Machinery Showcase
      </Typography>

      <Grid container spacing={4}>
        {oemodmImages.map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                title={item.title}
                sx={{
                  width: '100%',
                  height: 200,  // 設定圖片高度，讓所有圖片大小一致
                  objectFit: 'cover',
                }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default OEMODM;
