import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeContext } from '../ThemeContext';

const menuItems = ['Home', 'About', 'Services', 'HMI','IIOT Tech', 'OEM & ODM', 'Contact'];

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { mode, toggleTheme } = useContext(ThemeContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id.toLowerCase().replace(' & ', '-').replace(' ', '-'));
    if (element) {
      const navbarHeight = 64;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  return (
    <AppBar position="fixed" color="primary" sx={{ width: '100%' }}>
      <Container maxWidth="lg">
        <Toolbar>
          <Typography 
            variant="h3" 
            sx={{ 
              flexGrow: 1, 
              fontSize: { xs: '24px', md: '40px',fontWeight: 'bold' } // 標題字體大小，響應式設置
            }}
          >
            WEIYAN
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="toggle theme"
                onClick={toggleTheme}
                sx={{ mr: 2 }}
              >
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                <List>
                  {menuItems.map((item) => (
                    <ListItem button key={item} onClick={() => scrollToSection(item)}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {menuItems.map((item) => (
                <Button 
                  key={item} 
                  color="inherit" 
                  onClick={() => scrollToSection(item)}
                  sx={{ 
                    fontSize: { xs: '14px', md: '16px' }, // 調整字體大小，響應式
                    // fontWeight: 'bold'  // 增加字體粗細
                  }}
                >
                  {item}
                </Button>
              ))}
              <IconButton
                color="inherit"
                aria-label="toggle theme"
                onClick={toggleTheme}
                sx={{ ml: 2 }}
              >
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
