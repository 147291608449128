import React, { useState } from 'react';
import { Box, Typography, Grid, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function HMI() {
  const [openFirstVideo, setOpenFirstVideo] = useState(false);  // 控制第一個影片
  const [openSecondVideo, setOpenSecondVideo] = useState(false);  // 控制第二個影片

  const handleFirstVideoClick = () => {
    setOpenFirstVideo(true);  // 打開第一個影片
  };

  const handleSecondVideoClick = () => {
    setOpenSecondVideo(true);  // 打開第二個影片
  };

  const handleClose = () => {
    setOpenFirstVideo(false);  // 關閉影片
    setOpenSecondVideo(false);  // 關閉影片
  };

  return (
    <Box
      id="hmi"
      sx={{
        width: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',  // 縱向佈局
        justifyContent: 'flex-start',  // 讓內容靠上對齊
        alignItems: 'center',  // 水平居中
      }}
    >
      {/* 第一個 row */}
      <Grid container spacing={2} sx={{ width: '100%' }} alignItems="stretch">
        
        {/* 左側文字部分 */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', fontSize: { xs: '28px', sm: '36px' }, color: 'primary.main', }}>
              cMT X Series
            </Typography>
            <Typography variant="body1" paragraph>
              Fast x Smart x Reliable
            </Typography>
            <Typography variant="body1" paragraph>
              The cMT series products are available in sizes ranging from 4.3 inches to 15.6 inches, enabling you to select the size that best fits your requirements.
            </Typography>
            <Typography
              component="a"
              href="/files/cMTX_Brochure_ENG_20240621.pdf"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'secondary.main',
                fontWeight: 'bold',
                textDecoration: 'underline',
                cursor: 'pointer',
                mt: 0,
              }}
            >
              Download cMTX Brochure
            </Typography>
          </Box>
        </Grid>

        {/* 右側圖片部分 */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            component="img"
            src="/images/feature_cMTX.jpg"  // 替換成你的圖片路徑
            alt="cMT X Series"
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '80vh',
              objectFit: 'contain',
            }}
          />
        </Grid>
      </Grid>

      {/* 第二個 row：文字部分 */}
      <Box sx={{ width: '100%', mt: 4 }}> {/* 增加 margin-top 來分隔上方的內容 */}

        {/* 標題部分 */}
        <Grid container spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', fontSize: { xs: '28px', sm: '36px' }, color: 'primary.main', }}>
              Multiple ways for Remote Monitoring
            </Typography>
            
            <Typography variant="body1" paragraph>
              Pick the one that best suits you!
            </Typography>

            {/* 新增一個可下載PDF的文字連結 */}
            <Typography
              component="a"  // 將Typography設為可點擊的a標籤
              href="/files/Remote_Monitoring.pdf"  // 替換成你自己的PDF路徑
              target="_blank"  // 新視窗打開
              rel="noopener noreferrer"
              sx={{
                color: 'secondary.main',  // 設定顏色
                fontWeight: 'bold',  // 加粗文字
                textDecoration: 'underline',  // 增加下劃線
                cursor: 'pointer',  // 鼠標變成點擊手勢
              }}
            >
              Download Remote Monitoring Guide
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* 第二個 row：3個區塊 */}
      <Grid container spacing={4} sx={{ width: '100%' }} alignItems="stretch" mt={4}>
        {/* 第一個區塊 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: '#CECEFF', p: 3, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* 圖標 */}
            <Box
              component="img"
              src="/images/vnc-icon.jpg"  // 替換為你的圖標路徑
              alt="VNC Icon"
              sx={{ width: 40, height: 40, mb: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              The Classic: VNC
            </Typography>
            <Typography variant="body2" paragraph>
              Use VNC Viewer to monitor HMI screen synchronously. The classic way.
            </Typography>
          </Box>
        </Grid>
        
        {/* 第二個區塊 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: '#2F58CD', color: 'white', p: 3, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* 圖標 */}
            <Box
              component="img"
              src="/images/webview-icon.jpg"  // 替換為你的圖標路徑
              alt="WebView Icon"
              sx={{ width: 40, height: 40, mb: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              The Convenient: WebView
            </Typography>
            <Typography variant="body2" paragraph>
              Go app-less and monitor with a browser such as Chrome/Safari.
            </Typography>
          </Box>
        </Grid>

        {/* 第三個區塊 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: '#F4A261', color: 'white', p: 3, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* 圖標 */}
            <Box
              component="img"
              src="/images/cmt-icon.jpg"  // 替換為你的圖標路徑
              alt="cMT Icon"
              sx={{ width: 40, height: 40, mb: 2 }}
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              The Efficient: cMT Viewer
            </Typography>
            <Typography variant="body2" paragraph>
              Monitor with very fast refresh rate using Weintek’s exclusive app.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* 影片播放功能 */}
      {/* 第一個影片 */}
      <Dialog
        open={openFirstVideo}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ position: 'relative', padding: 0 }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/bUZmtHQcwEY"  // 替換為你的第一個影片連結
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>

      {/* 第二個影片 */}
      <Dialog
        open={openSecondVideo}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ position: 'relative', padding: 0 }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/rjtuKCJMkzk"  // 替換為你的第二個影片連結
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default HMI;
