import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

function Contact() {
  return (
    <Box
      id="contact"
      sx={{
        width: '100%',
        p: 2,  // 控制 padding
      }}
    >
      {/* Contact Us 標題 */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          mb: 4,  // 控制 margin-bottom
          color: 'primary.main',
        }}
      >
        Contact Us
      </Typography>

      <Grid container spacing={4} alignItems="center">
        {/* 左側：聯絡資訊 */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2 }}>
            {/* Location */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <LocationOnIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Location:
                </Typography>
                <Typography variant="body1">
                14F., No. 33, Sec. 2, Sanmin Rd., Banqiao Dist., New Taipei City 220337 , Taiwan (R.O.C.)
                </Typography>
              </Box>
            </Box>

            {/* Email */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <EmailIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Email:
                </Typography>
                <Link href="mailto:service@weiyantc.com" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  service@weiyantc.com
                </Link>
              </Box>
            </Box>

            {/* Call */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ color: 'primary.main', mr: 2 }} />
              <Box>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Call:
                </Typography>
                <Link href="tel:+886229586701" sx={{ textDecoration: 'none', color: 'text.primary' }}>
                  +886 2 2958 6701
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* 右側：嵌入地圖 */}
        <Grid item xs={12} md={6}>
          <Box
            component="iframe"
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12160.933797602409!2d121.47563287856403!3d25.01821253248088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a95d7248fad9%3A0xba78784587b24551!2zMjIw5paw5YyX5biC5p2_5qGl5Yy65LiJ5rCR6Lev5LqM5q61MzPomZ8xNOaokw!5e0!3m2!1szh-TW!2stw!4v1728203798478!5m2!1szh-TW!2stw" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
