import React, { useState } from 'react';
import { Box, Typography, Button, Grid, useTheme, useMediaQuery, Dialog, DialogContent, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false); // 控制 Dialog 開關

  const handleVideoClick = () => {
    setOpen(true); // 點擊後顯示影片
  };

  const handleClose = () => {
    setOpen(false); // 點擊關閉按鈕後隱藏影片
  };

  return (
    <Box
      id="home"
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.paper',
        color: 'text.primary',
        p: 2,  // 統一的 padding
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* 左側文字列 */}
        <Grid item xs={12} md={5}>
          <Box >
            <Typography
              component="h1"
              gutterBottom
              fontWeight="bold"
              sx={{
                fontSize: isMobile ? '20px' : '30px',color: 'primary.main',
              }}
            >
              Delivering better solutions
            </Typography>
            <Typography variant={isMobile ? "body1" : "h5"} paragraph>
              We aim to become a global reference in the electrical machinery field, providing a diverse product line to offer efficient and complete solutions for our customers.
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Button
                variant="contained"
                size={isMobile ? "medium" : "large"}
                startIcon={<PlayArrowIcon />}
                onClick={handleVideoClick}
              >
                2-Minute Introductory Video
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* 右側圖片列 */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              boxShadow: 3,
              padding: 0,
            }}
          >
            <Box
              component="img"
              src="/images/home.png"
              alt="為您提供更好的解決方案"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                display: 'block',
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* 彈出窗口模態框 */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ position: 'relative', padding: 0 }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: theme.palette.common.white,
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/LoklKEfhFoE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Home;
