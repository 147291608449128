import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import useFadeInOnScroll from '../hooks/useFadeInOnScroll';  // 引入hook

function IIOTTech() {
  return (
    <Box
      id="iiot-tech"
      sx={{
        width: '100%',
        p: 2,  // 保留 padding 設定
        m: 0,  // 取消 margin
        backgroundColor: 'background.default',
      }}
    >
      {/* 使用 Grid 分成兩個 row */}
      <Grid container spacing={4}>
        
        {/* 第一個 row：文字部分 */}
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', fontSize: { xs: '28px', sm: '36px' }, color: 'primary.main' }}
          >
            IIOT Tech Solutions
          </Typography>

          <Typography variant="body1" paragraph sx={{ mt: 2, color: 'text.secondary' }}>
            <strong>Industrial protocol standards</strong>  {/* 加粗標題 */}<br /> 
            Support Modbus TCP/IP and OPC UA, simplifying communication between various devices and enabling smoother system integration.
          </Typography>

          <Typography variant="body1" paragraph sx={{ mt: 2, color: 'text.secondary' }}>
            <strong>With over 400 communication protocols</strong><br /> 
            Gateway support all major PLC brands, including exclusive support for Siemens S7-1200/1500 and Omron NJ/NX series.
          </Typography>

          <Typography variant="body1" paragraph sx={{ mt: 2, color: 'text.secondary' }}>
            <strong>Third-party Cloud Platforms</strong><br /> 
            Easily connect to AWS, Azure, Google Cloud, Alibaba Cloud, IBM Cloud, and any other platform that supports MQTT.
          </Typography>

          {/* PDF 下載連結 */}
          <Typography
            component="a"
            href="/files/Gateway_Brochure_ENG.pdf"  // 替換為你的PDF路徑
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'secondary.main',
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
              mt: 0,
            }}
          >
            Download Gateway_Brochure
          </Typography>
        </Grid>

        {/* 第二個 row：圖片部分 */}
        <Grid item xs={12}>
          <Box
            component="img"
            src="/images/iiot-tech-background.jpg"  // 替換成你的圖片路徑
            alt="IIOT Tech"
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',  // 保持圖片等比例顯示
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default IIOTTech;
