import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';

function About() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      id="about"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        color: 'text.primary',
        p: 2,   // 統一的 padding
      }}
    >
      <Grid container spacing={4}>
        {/* 左側文字欄位 */}
        <Grid item xs={12} md={6}>
          <Typography
            component="h2"
            gutterBottom
            sx={{
              fontSize: isMobile ? '24px' : '30px',
              fontWeight: 'bold',
              lineHeight: 1.2,
              color: 'primary.main',
            }}
          >
            About Us
          </Typography>

          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isMobile ? '16px' : '18px',
              mb: 2,
            }}
          >
            We specialize in the sale of FA industrial control products, becoming a key supplier for numerous factories.
            By leveraging <strong>IoT technology</strong>, we enhance machine efficiency and establish strategic partnerships
            with many manufacturers.
          </Typography>

          <Typography
            variant="h6"
            component="p"
            sx={{
              fontWeight: 'bold',
              mt: 3,
              fontSize: isMobile ? '18px' : '22px',
            }}
          >
            Our Product Range:
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isMobile ? '16px' : '18px',
              mb: 2,
            }}
          >
            Our products include: <em>Variable Power Supplies, Low-Voltage Programmable Logic Controllers,
            Human-Machine Interfaces, Inverter Drives, and Machine Safety Solutions.</em>
          </Typography>
        </Grid>

        {/* 右側文字欄位 */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontWeight: 'bold',
              fontSize: isMobile ? '18px' : '22px',
              mt: { xs: 4, md: 0 },
            }}
          >
            Our Commitment to Quality:
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isMobile ? '16px' : '18px',
              mb: 2,
            }}
          >
            Our partners are dedicated to delivering high-quality products on time, allowing us to offer 
            <strong>optimal solutions</strong> for your needs.
          </Typography>

          <Typography
            variant="h6"
            component="p"
            sx={{
              fontWeight: 'bold',
              fontSize: isMobile ? '18px' : '22px',
              mt: 3,
            }}
          >
            Future Vision:
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isMobile ? '16px' : '18px',
            }}
          >
            Moving forward, we remain committed to delivering the best products and services with a 
            <strong>professional</strong>, <strong>passionate</strong>, and <strong>dedicated</strong> approach.
            We look forward to working together to achieve a <strong>win-win outcome</strong>.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
