import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button, useTheme, useMediaQuery } from '@mui/material';

const servicesData = [
  {
    title: "HMI Series",
    description: "As an authorized distributor of Weintek, we offer both Basic and Advanced full-size HMIs. These intuitive and user-friendly interfaces ensure smooth operation and real-time information display, meeting diverse industrial needs.",
    image: "/images/service-hmi.png",
    link: "https://www.weintek.com/"
  },
  {
    title: "PLC Series",
    description: "We distribute Mitsubishi and Siemens PLCs, providing solutions that offer enhanced stability, reliability, and efficiency, helping clients improve production processes, reduce costs, and ensure operational safety.",
    image: "/images/service-plc.png",
    link: "https://www.mitsubishielectric.com/fa/"
  },
  {
    title: "Power Supply Series",
    description: "As an official distributor of Meanwell power supplies, we deliver high-quality and reliable power solutions. These efficient and stable products are trusted across various industrial applications.",
    image: "/images/service-power.png",
    link: "https://www.meanwell.com/"
  },
  {
    title: "OEM & ODM",
    description: "Our OEM and ODM services are backed by precise design and manufacturing, ensuring that we deliver products with consistent quality, accurate dimensions, and shapes, customized to meet specific requirements.",
    image: "/images/service-dealer.png",
  }
];

function Services() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      id="services"
      sx={{
        bgcolor: 'background.default',
        color: 'text.primary',
        p: 2,  // 統一的 padding
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{ mb: 4, fontWeight: 'bold', fontSize: isMobile ? '28px' : '36px',color: 'primary.main', }}
      >
        SERVICES
      </Typography>
      <Typography variant="body1" sx={{ mb: 6, color: 'text.secondary' }}>
        We are a distributor of industrial control parts, offering products such as Mitsubishi PLCs, inverters, and Meanwell power supplies. Additionally, we provide customized services to help clients develop HMI and PLC programs.
      </Typography>

      <Grid container spacing={4}>
        {servicesData.map((service, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 4, boxShadow: 3, height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  image={service.image}
                  alt={service.title}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    mr: 2,
                  }}
                />
                <CardContent sx={{ textAlign: 'left',}}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {service.description}
                  </Typography>
                </CardContent>
              </Box>

              {/* Button 在卡片右下角 */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  href={service.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Services;
