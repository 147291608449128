import React from 'react';
import { CssBaseline, Box, Container } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ThemeProvider } from './ThemeContext';
import Home from './sections/Home';
import About from './sections/About';
import Services from './sections/Services';
import IIOTTech from './sections/IIOTTech';
import HMI from './sections/HMI';
import OEMODM from './sections/OEMODM';
import Contact from './sections/Contact';

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />

      {/* Navbar 全寬顯示 */}
      <Box sx={{ width: '100%' }}>
        <Navbar />
      </Box>

      <Box sx={{ paddingTop: { xs: '56px', sm: '64px' } }}>
        {/* 使用 Container 包裹網站的主要部分，統一控制間距 */}
        <Container>
          {/* 每個 section 間距統一設置 */}
          <Box sx={{ p: 1 }}> {/* 統一設置每個 section 之間的 padding */}
            <Home />
          </Box>
          <Box sx={{ p: 1 }}>
            <About />
          </Box>
          <Box sx={{ p: 1 }}>
            <Services />
          </Box>
          <Box sx={{ p: 1 }}>
            <HMI />
          </Box>
          <Box sx={{ p: 1 }}>
            <IIOTTech />
          </Box>

          <Box sx={{ p: 1 }}>
            <OEMODM />
          </Box>
          <Box sx={{ p: 1 }}>
            <Contact />
          </Box>
        </Container>
      </Box>

      {/* Footer 全寬顯示 */}
      <Box sx={{ width: '100%' }}>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
