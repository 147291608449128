import React from 'react';
import { Box, Container, Typography, Toolbar, AppBar, useTheme } from '@mui/material';

function Footer() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"  // 使用 static 來讓 Footer 固定在底部
      color="primary"
      sx={{ top: 'auto', bottom: 0, width: '100%' }}  // 保持全螢幕寬度
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'center', alignItems: 'center' }}>  {/* 文字置中 */}
          <Typography variant="h6" align="center">
            © {new Date().getFullYear()} WEIYAN Technology Co., Ltd. All Rights Reserved.
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Footer;
